// ForgotPassword.js
import React, { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import './styles/ForgotPassword.css';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setMessage('');
    setError(null);

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Ett återställningsmail har skickats till din e-postadress.');
      setTimeout(() => navigate('/login'), 5000); // Tillbaka till inloggningssidan efter 5 sekunder
    } catch (error) {
      setError('Ett fel inträffade: ' + error.message);
    }
  };

  return (
    <div className="forgot-password-container">
      <div className="forgot-password-box">
        <h2>Återställ Lösenord</h2>
        <form onSubmit={handlePasswordReset}>
          <label htmlFor="email">E-post</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Ange din e-postadress"
            required
          />
          <button type="submit">Skicka återställningsmail</button>
        </form>
        {message && <p className="success-message">{message}</p>}
        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
};

export default ForgotPassword;