import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAGvq9A1sRbjaU6nEKFNeXDfdkISci5UdI",
  authDomain: "crmbase-60904.firebaseapp.com",
  projectId: "crmbase-60904",
  storageBucket: "crmbase-60904.appspot.com",
  messagingSenderId: "995978144614",
  appId: "1:995978144614:web:18e400fd90579511ca36a7"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };