// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import UserDashboard from './components/UserDashboard';
import SalesManagerDashboard from './components/SalesManagerDashboard';
import AddUser from './components/AddUser';
import SalesInfo from './components/SalesInfo';
import SalesSpecification from './components/SalesSpecification';
import UserSalesSpecification from './components/UserSalesSpecification';
import UserProfilePage from './components/UserProfilePage';
import MainLayout from './components/MainLayout';
import ProtectedRoute from './components/ProtectedRoute';
import ManageUsers from './components/ManageUsers';
import ManageSalesManagerUsers from './components/ManageSalesManagerUsers';
import SalaryStatistics from './components/SalaryStatistics';
import FinalReport from './components/FinalReport';
import Statistics from './components/Statistics';
import SalesManagerStatistics from './components/SalesManagerStatistics';
import UserStatistics from './components/UserStatistics';
import Sales from './components/Sales';
import ControllerStatistics from './components/ControllerStatistics';
import Företag from './components/Företag';
import ForgotPassword from './components/ForgotPassword';
import { AuthProvider } from './auth';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Direkttillgång till login */}
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />

          {/* Main Layout för skyddade sidor */}
          <Route element={<MainLayout />}>
            <Route 
              path="/admin/dashboard" 
              element={
                <ProtectedRoute role="admin">
                  <Dashboard />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/user/dashboard" 
              element={
                <ProtectedRoute role={["user", "sales-manager"]}>
                  <UserDashboard />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/sales-manager/dashboard" 
              element={
                <ProtectedRoute role="sales-manager">
                  <SalesManagerDashboard />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/controller/dashboard" 
              element={
                <ProtectedRoute role="controller">
                  <Dashboard />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/controller/statistics" 
              element={
                <ProtectedRoute role="controller">
                  <ControllerStatistics />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/add-user" 
              element={
                <ProtectedRoute role={["admin", "sales-manager"]}>
                  <AddUser />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/sales-info" 
              element={
                <ProtectedRoute role="admin">
                  <SalesInfo />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/sales-specification" 
              element={
                <ProtectedRoute role="admin">
                  <SalesSpecification />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/user/sales-specification" 
              element={
                <ProtectedRoute role={["user", "sales-manager"]}>
                  <UserSalesSpecification />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/profile" 
              element={
                <ProtectedRoute role={["user", "sales-manager", "admin", "controller"]}>
                  <UserProfilePage />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/admin/manage-users" 
              element={
                <ProtectedRoute role="admin"> 
                  <ManageUsers />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/sales-manager/manage-users" 
              element={
                <ProtectedRoute role="sales-manager">
                  <ManageSalesManagerUsers />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/user-profile/:id" 
              element={
                <ProtectedRoute role={["admin", "sales-manager"]}>
                  <UserProfilePage />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/admin/salary-statistics" 
              element={
                <ProtectedRoute role="admin">
                  <SalaryStatistics />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/sales-manager/final-report" 
              element={
                <ProtectedRoute role="sales-manager">
                  <FinalReport />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/admin/statistics" 
              element={
                <ProtectedRoute role="admin">
                  <Statistics />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/sales-manager/statistics" 
              element={
                <ProtectedRoute role="sales-manager">
                  <SalesManagerStatistics />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/user/statistics" 
              element={
                <ProtectedRoute role="user">
                  <UserStatistics />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/sales" 
              element={
                <ProtectedRoute role={["user", "sales-manager"]}>
                  <Sales />
                </ProtectedRoute>
              } 
            />

            {/* Företag - endast åtkomst för controller */}
            <Route 
              path="/controller/företag" 
              element={
                <ProtectedRoute role="controller">
                  <Företag />
                </ProtectedRoute>
              } 
            />
          </Route>

          {/* Unauthorized och 404-hantering */}
          <Route path="/unauthorized" element={<div>Unauthorized Access</div>} />
          <Route path="*" element={<Navigate to="/login" replace />} /> {/* Fångar alla icke-definierade paths */}
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;