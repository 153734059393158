import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import './styles/Statistics.css';
import { useAuth } from '../auth';

const Statistics = () => {
  const [salesData, setSalesData] = useState([]);
  const [finalReports, setFinalReports] = useState([]);
  const [allSalesData, setAllSalesData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState('');
  const [selectedSalesperson, setSelectedSalesperson] = useState('');
  const [selectedManager, setSelectedManager] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [salespersons, setSalespersons] = useState([]);
  const [periods, setPeriods] = useState([]);
  const [dataSource, setDataSource] = useState('sales');
  const [managers, setManagers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'asc' });
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [totalSales, setTotalSales] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [statusOptions, setStatusOptions] = useState([]);

  // Nytt state för att hantera betalningsdatum
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [currentSale, setCurrentSale] = useState(null);
  const [paidDate, setPaidDate] = useState('');

  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      if (!currentUser) {
        console.log("Ingen inloggad användare");
        return;
      }

      try {
        const usersQuerySnapshot = await getDocs(collection(db, 'users'));
        const usersDataArray = usersQuerySnapshot.docs.map(doc => {
          const userData = doc.data();
          return { id: doc.id, ...userData };
        });

        const salesManagers = usersDataArray.filter(user => user.role === 'sales-manager');
        setManagers(salesManagers);

        const allSales = [];
        const customerSet = new Set();
        const salespersonsSet = new Set();

        for (const user of usersDataArray) {
          const userSalesCollectionRef = collection(db, `users/${user.id}/sales`);
          const userSalesSnapshot = await getDocs(userSalesCollectionRef);

          userSalesSnapshot.docs.forEach(doc => {
            const salesData = doc.data();
            const salespersonName = `${user.firstName} ${user.lastName}`;

            allSales.push({
              ...salesData,
              userId: user.id,
              salesId: doc.id,
              date: salesData.signDate || 'N/A',
              customerName: salesData.name || 'N/A',
              shares: salesData.shares || 0,
              status: salesData.status || 'N/A',
              invoiced: salesData.invoiced || false,
              paid: salesData.paid || false,
              paidDate: salesData.paidDate || '', // Nytt fält
              company: salesData.company || 'N/A',
              salesperson: salespersonName,
              managerUid: user.managerUid || '',
            });

            salespersonsSet.add(salespersonName);

            if (salesData.company) customerSet.add(salesData.company);
          });
        }

        setAllSalesData(allSales);
        setCustomers([...customerSet]);
        setSalespersons([...salespersonsSet]);

        const salesSpecificationsArray = usersDataArray.flatMap(userData => {
          const salesSpecifications = userData.salesSpecifications || {};
          return Object.entries(salesSpecifications).map(([period, details]) => ({
            period,
            totalApproved: details.totalApproved || 0,
            salesperson: `${userData.firstName} ${userData.lastName}`,
            salesId: userData.salesId,
            managerUid: userData.managerUid,
            date: period,
            type: 'Sales Specification',
          }));
        });

        const finalReportsQuerySnapshot = await getDocs(collection(db, 'finalReports'));
        const finalReportsArray = finalReportsQuerySnapshot.docs.flatMap(doc => {
          const data = doc.data();
          return Object.keys(data.salesData || {}).map(salesId => ({
            ...data.salesData[salesId],
            date: data.date,
            reportId: doc.id,
            managerUid: data.managerUid,
            location: data.location || 'N/A',
            organisation: data.organisation || 'N/A',
          }));
        });

        setPeriods([...new Set(salesSpecificationsArray.map(spec => spec.period))]);

        setSalesData(salesSpecificationsArray);
        setFinalReports(finalReportsArray);

        const statusSet = new Set(allSales.map(item => item.status).filter(Boolean));
        statusSet.add('Fakturerad');
        statusSet.add('Utbetald');
        setStatusOptions([...statusSet]);

        if (dataSource === 'salesSpecification') {
          setFilteredData(salesSpecificationsArray);
          calculateTotalSales(salesSpecificationsArray);
        } else if (dataSource === 'finalReport') {
          setFilteredData(finalReportsArray);
          calculateTotalSales(finalReportsArray);
        } else {
          setFilteredData(allSales);
          calculateTotalSales(allSales);
        }
      } catch (error) {
        console.error("Fel vid hämtning av data:", error);
      }
    };

    fetchData();
  }, [currentUser, dataSource]);

  const handleFilterChange = () => {
    let filtered = [];
    if (dataSource === 'salesSpecification') filtered = [...salesData];
    else if (dataSource === 'finalReport') filtered = [...finalReports];
    else if (dataSource === 'sales') filtered = [...allSalesData];

    if (selectedManager) filtered = filtered.filter(item => item.managerUid === selectedManager);
    if (selectedSalesperson) {
      filtered = filtered.filter(item => item.salesperson === selectedSalesperson);
      const salespersonCustomers = new Set(filtered.map(item => item.company || item.customerName));
      setCustomers([...salespersonCustomers]);
    }
    if (selectedCustomer) {
      filtered = filtered.filter(item => (item.company || item.customerName) === selectedCustomer);
    }
    if (selectedPeriod) filtered = filtered.filter(item => item.date === selectedPeriod);

    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      filtered = filtered.filter(item => {
        const itemDate = new Date(item.date);
        return itemDate >= start && itemDate <= end;
      });
    }

    if (selectedStatus) {
      if (selectedStatus === 'Fakturerad') {
        filtered = filtered.filter(item => item.invoiced);
      } else if (selectedStatus === 'Utbetald') {
        filtered = filtered.filter(item => item.paid);
      } else {
        filtered = filtered.filter(item => item.status === selectedStatus);
      }
    }

    setFilteredData(sortData(filtered));
    calculateTotalSales(filtered);
  };

  const handleResetFilters = () => {
    setSelectedPeriod('');
    setSelectedSalesperson('');
    setSelectedManager('');
    setSelectedCustomer('');
    setStartDate('');
    setEndDate('');
    setSelectedStatus('');
    handleFilterChange();
  };

  const calculateTotalSales = (data) => {
    const total = data.reduce((sum, item) => {
      const salesValue = item.totalApproved || item.sales || item.amount || 0;
      return sum + parseFloat(salesValue);
    }, 0);
    setTotalSales(total);
  };

  const handleCheckboxChange = async (saleId, userId, field) => {
    try {
      const saleRef = doc(db, `users/${userId}/sales/${saleId}`);
      const sale = filteredData.find(sale => sale.salesId === saleId);
      const newValue = !sale[field];

      if (field === 'paid' && newValue) {
        // Om 'paid' kryssrutan markeras, visa datumväljaren
        setCurrentSale({ saleId, userId });
        setShowDatePicker(true);
      } else {
        // Uppdatera bara fältet
        await updateDoc(saleRef, { [field]: newValue });
        setFilteredData(prevData =>
          prevData.map(sale =>
            sale.salesId === saleId ? { ...sale, [field]: newValue } : sale
          )
        );
      }
    } catch (error) {
      console.error('Error updating checkbox:', error);
      alert('Kunde inte uppdatera. Kontrollera dina behörigheter och försök igen.');
    }
  };

  const handleDateChange = (event) => {
    setPaidDate(event.target.value);
  };

  const handleDateSubmit = async () => {
    if (!paidDate) {
      alert('Vänligen välj ett datum.');
      return;
    }

    try {
      const { saleId, userId } = currentSale;
      const saleRef = doc(db, `users/${userId}/sales/${saleId}`);

      await updateDoc(saleRef, { paid: true, paidDate: paidDate });

      setFilteredData(prevData =>
        prevData.map(sale =>
          sale.salesId === saleId ? { ...sale, paid: true, paidDate: paidDate } : sale
        )
      );

      setShowDatePicker(false);
      setPaidDate('');
      setCurrentSale(null);
    } catch (error) {
      console.error('Error updating paid date:', error);
      alert('Kunde inte uppdatera betalningsdatumet. Försök igen.');
    }
  };

  const sortData = (data) => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });
    return sortedData;
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') direction = 'desc';
    setSortConfig({ key, direction });
    setFilteredData(sortData(filteredData));
  };

  return (
    <div className="statistics-container">
      <h1>Admin Statistik</h1>

      <table className="filter-table">
        <tbody>
          <tr>
            <td>
              <label>Datakälla:</label>
              <select value={dataSource} onChange={(e) => setDataSource(e.target.value)}>
                <option value="sales">Försäljning</option>
                <option value="salesSpecification">Försäljningsspecifikation</option>
                <option value="finalReport">Slutrapport</option>
              </select>
            </td>
            <td>
              <label>Säljchef:</label>
              <select value={selectedManager} onChange={(e) => setSelectedManager(e.target.value)}>
                <option value="">Alla</option>
                {managers.map(manager => (
                  <option key={manager.id} value={manager.id}>{manager.firstName} {manager.lastName}</option>
                ))}
              </select>
            </td>
            <td>
              <label>Säljare:</label>
              <select value={selectedSalesperson} onChange={(e) => setSelectedSalesperson(e.target.value)}>
                <option value="">Alla</option>
                {salespersons.map((salesperson, index) => (
                  <option key={index} value={salesperson}>{salesperson}</option>
                ))}
              </select>
            </td>
            <td>
              <label>Kund:</label>
              <select value={selectedCustomer} onChange={(e) => setSelectedCustomer(e.target.value)}>
                <option value="">Alla</option>
                {customers.map((customer, index) => (
                  <option key={index} value={customer}>{customer}</option>
                ))}
              </select>
            </td>
          </tr>
          <tr>
            <td>
              <label>Period:</label>
              <select value={selectedPeriod} onChange={(e) => setSelectedPeriod(e.target.value)}>
                <option value="">Alla</option>
                {periods.map((period, index) => (
                  <option key={index} value={period}>{period}</option>
                ))}
              </select>
            </td>
            <td>
              <label>Startdatum:</label>
              <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
            </td>
            <td>
              <label>Slutdatum:</label>
              <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
            </td>
            <td>
              <label>Status:</label>
              <select value={selectedStatus} onChange={(e) => setSelectedStatus(e.target.value)}>
                <option value="">Alla</option>
                {statusOptions.map((status, index) => (
                  <option key={index} value={status}>{status}</option>
                ))}
              </select>
            </td>
          </tr>
          <tr>
            <td colSpan="4" className="button-container">
              <button className="filter-button" onClick={handleFilterChange}>Filtrera</button>
              <button className="reset-button" onClick={handleResetFilters}>Nollställ</button>
            </td>
          </tr>
        </tbody>
      </table>

      <div className="total-sales">
        <h3>Totalt Försäljning: {totalSales}</h3>
      </div>

      <div className="table-container">
        <table className="statistics-table">
          <thead>
            <tr>
              <th>Fakturerad</th>
              <th>Utbetald</th>
              <th>Kund</th>
              <th>Antal Aktier</th>
              <th onClick={() => handleSort('date')}>
                Datum {sortConfig.key === 'date' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
              </th>
              {dataSource === 'finalReport' && <th>Organisation</th>}
              {dataSource === 'sales' && <th>Företag</th>}
              <th>Försäljning</th>
              <th>Status</th>
              <th>Betalningsdatum</th> {/* Ny kolumn */}
            </tr>
          </thead>
          <tbody>
            {filteredData.length === 0 ? (
              <tr>
                <td colSpan={dataSource === 'finalReport' ? 9 : 8}>Ingen data tillgänglig</td>
              </tr>
            ) : (
              filteredData.map((item, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="checkbox"
                      checked={item.invoiced}
                      onChange={() => handleCheckboxChange(item.salesId, item.userId, 'invoiced')}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={item.paid}
                      onChange={() => handleCheckboxChange(item.salesId, item.userId, 'paid')}
                    />
                  </td>
                  <td>{item.customerName || 'N/A'}</td>
                  <td>{item.shares || 'N/A'}</td>
                  <td>{item.date || 'N/A'}</td>
                  {dataSource === 'finalReport' && <td>{item.organisation || 'N/A'}</td>}
                  {dataSource === 'sales' && <td>{item.company || 'N/A'}</td>}
                  <td>{item.totalApproved || item.sales || item.amount || 'N/A'}</td>
                  <td>{item.status || 'N/A'}</td>
                  <td>{item.paidDate || 'Ej angivet'}</td> {/* Visa betalningsdatumet */}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {/* Modal för datumväljare */}
      {showDatePicker && (
        <div className="modal">
          <div className="modal-content">
            <h3>Välj betalningsdatum</h3>
            <input type="date" value={paidDate} onChange={handleDateChange} />
            <button onClick={handleDateSubmit}>Spara</button>
            <button onClick={() => setShowDatePicker(false)}>Avbryt</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Statistics;