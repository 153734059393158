// ControllerStatistics.js
import React, { useState, useEffect } from 'react';
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import './styles/ControllerStatistics.css';

const ControllerStatistics = () => {
  const [finalReports, setFinalReports] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedSalesperson, setSelectedSalesperson] = useState('');
  const [salespersons, setSalespersons] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [totalSales, setTotalSales] = useState(0);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [statusFilter, setStatusFilter] = useState(''); // Ny statusfilter

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersSnapshot = await getDocs(collection(db, 'users'));
        const allSalesData = [];

        // Hämta försäljningsdata för alla användare
        for (const userDoc of usersSnapshot.docs) {
          const userData = userDoc.data();
          const salesCollectionRef = collection(db, `users/${userDoc.id}/sales`);
          const salesSnapshot = await getDocs(salesCollectionRef);

          salesSnapshot.forEach(saleDoc => {
            const saleData = saleDoc.data();
            allSalesData.push({
              ...saleData,
              name: `${userData.firstName} ${userData.lastName}`,
              userId: userDoc.id,
              saleId: saleDoc.id,
              date: saleData.signDate || 'N/A',
              organisation: saleData.company || 'N/A',
              customerName: saleData.name || 'N/A',
              shares: saleData.shares || 'N/A',
              sales: saleData.amount || 0,
              status: saleData.status || 'Pending'
            });
          });
        }

        setFinalReports(allSalesData);

        // Extrahera unika säljarnamn och kundnamn
        const salespersonsSet = new Set(allSalesData.map(item => item.name));
        setSalespersons([...salespersonsSet]);

        const customersSet = new Set(allSalesData.map(item => item.organisation));
        setCustomers([...customersSet]);

        setFilteredData(allSalesData);
        calculateTotalSales(allSalesData);
      } catch (error) {
        console.error("Fel vid hämtning av data:", error);
      }
    };

    fetchData();
  }, []);

  // Uppdatera status till "betald" i Firestore
  const updateStatus = async (userId, saleId) => {
    try {
      const saleDocRef = doc(db, `users/${userId}/sales/${saleId}`);
      await updateDoc(saleDocRef, { status: 'betald' });

      setFinalReports(prevReports =>
        prevReports.map(item =>
          item.saleId === saleId && item.userId === userId ? { ...item, status: 'betald' } : item
        )
      );

      setFilteredData(prevData =>
        prevData.map(item =>
          item.saleId === saleId && item.userId === userId ? { ...item, status: 'betald' } : item
        )
      );

      alert("Statusen har uppdaterats till 'betald'.");
    } catch (error) {
      console.error("Fel vid uppdatering av status:", error);
      alert("Ett fel uppstod vid uppdatering av status.");
    }
  };

  // Filtrera data baserat på datum, säljare, kund och status
  const handleFilterChange = () => {
    let filtered = [...finalReports];

    if (selectedSalesperson) {
      filtered = filtered.filter(item => item.name === selectedSalesperson);
    }

    if (selectedCustomer) {
      filtered = filtered.filter(item => item.organisation === selectedCustomer);
    }

    if (statusFilter) {
      filtered = filtered.filter(item => item.status === statusFilter);
    }

    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      filtered = filtered.filter(item => {
        const itemDate = new Date(item.date);
        return itemDate >= start && itemDate <= end;
      });
    }

    setFilteredData(filtered);
    calculateTotalSales(filtered);
  };

  // Beräkna total försäljning
  const calculateTotalSales = (data) => {
    const total = data.reduce((sum, item) => {
      const salesValue = item.sales || 0;
      return sum + parseFloat(salesValue);
    }, 0);
    setTotalSales(total);
  };

  return (
    <div className="statistics-container">
      <h1>Controller Statistik</h1>

      {/* Filtersektion */}
      <div className="filter-container">
        <div>
          <label>Välj Säljare:</label>
          <select value={selectedSalesperson} onChange={(e) => setSelectedSalesperson(e.target.value)}>
            <option value="">Alla</option>
            {salespersons.map((salesperson, index) => (
              <option key={index} value={salesperson}>{salesperson}</option>
            ))}
          </select>
        </div>

        <div>
          <label>Välj Kund:</label>
          <select value={selectedCustomer} onChange={(e) => setSelectedCustomer(e.target.value)}>
            <option value="">Alla</option>
            {customers.map((customer, index) => (
              <option key={index} value={customer}>{customer}</option>
            ))}
          </select>
        </div>

        <div>
          <label>Status:</label>
          <select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
            <option value="">Alla</option>
            <option value="Pending">Pending</option>
            <option value="betald">Betald</option>
          </select>
        </div>

        <div>
          <label>Startdatum:</label>
          <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
        </div>
        <div>
          <label>Slutdatum:</label>
          <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        </div>
        <button className="filter-button" onClick={handleFilterChange}>Filtrera</button>
      </div>

      {/* Totalsumma */}
      <div className="total-sales">
        <h3>Totalt Försäljning: {totalSales}</h3>
      </div>

      {/* Tabell för att visa resultat */}
      <div className="table-container">
        <table className="statistics-table">
          <thead>
            <tr>
              <th>Säljare</th>
              <th>Datum</th>
              <th>Organisation</th>
              <th>Kund</th>
              <th>Aktier</th>
              <th>Försäljning</th>
              <th>Status</th>
              <th>Åtgärder</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length === 0 ? (
              <tr>
                <td colSpan="8">Ingen data tillgänglig</td>
              </tr>
            ) : (
              filteredData.map((item, index) => (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>{item.date}</td>
                  <td>{item.organisation}</td>
                  <td>{item.customerName || 'N/A'}</td>
                  <td>{item.shares || 'N/A'}</td>
                  <td>{item.sales || 'N/A'}</td>
                  <td>{item.status}</td>
                  <td>
                    {item.status !== 'betald' && (
                      <button onClick={() => updateStatus(item.userId, item.saleId)} className="status-button">
                        Markera som betald
                      </button>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ControllerStatistics;