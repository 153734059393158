import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, addDoc, getDocs, deleteDoc, doc } from 'firebase/firestore';
import './styles/Företag.css';

const Företag = () => {
  const [companyName, setCompanyName] = useState('');
  const [companies, setCompanies] = useState([]);
  const [message, setMessage] = useState('');

  // Hämta företag från Firestore
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const companiesCollectionRef = collection(db, 'companies');
        const querySnapshot = await getDocs(companiesCollectionRef);
        const companiesArray = querySnapshot.docs.map(doc => ({
          id: doc.id,
          name: doc.data().name,
        }));
        setCompanies(companiesArray);
      } catch (error) {
        console.error('Error fetching companies:', error);
        setMessage('Ett fel uppstod vid hämtning av företag.');
      }
    };

    fetchCompanies();
  }, []);

  // Hantera formulärinlämning för att lägga till företag
  const handleAddCompany = async (e) => {
    e.preventDefault();

    try {
      const companiesCollectionRef = collection(db, 'companies');
      const docRef = await addDoc(companiesCollectionRef, { name: companyName });

      // Lägg till nytt företag i state
      setCompanies([...companies, { id: docRef.id, name: companyName }]);
      setCompanyName('');
      setMessage('Företaget har lagts till!');
    } catch (error) {
      console.error('Error adding company:', error);
      setMessage('Ett fel uppstod vid tillägg av företag.');
    }
  };

  // Hantera borttagning av företag
  const handleDeleteCompany = async (companyId) => {
    try {
      const companyDocRef = doc(db, 'companies', companyId);
      await deleteDoc(companyDocRef);

      // Uppdatera listan efter borttagning
      setCompanies(companies.filter(company => company.id !== companyId));
      setMessage('Företaget har tagits bort.');
    } catch (error) {
      console.error('Error deleting company:', error);
      setMessage('Ett fel uppstod vid borttagning av företag.');
    }
  };

  return (
    <div className="företag-container">
      <h1>Lägg till Företag</h1>
      <form onSubmit={handleAddCompany} className="företag-form">
        <div className="form-group">
          <label>Företagsnamn:</label>
          <input
            type="text"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            required
          />
        </div>
        <button type="submit">Lägg till företag</button>
      </form>

      {message && <p className="message">{message}</p>}

      <h2>Företagslista</h2>
      <ul className="company-list">
        {companies.map((company) => (
          <li key={company.id}>
            {company.name}
            <button onClick={() => handleDeleteCompany(company.id)} className="delete-button">
              Ta bort
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Företag;