import React, { useState, useEffect } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { useAuth } from '../auth';
import './styles/UserStatistics.css';

const UserStatistics = () => {
  const [userSales, setUserSales] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [totalSales, setTotalSales] = useState(0);
  const [selectedSales, setSelectedSales] = useState(new Set());

  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchUserSales = async () => {
      if (!currentUser) {
        console.log("Ingen inloggad användare.");
        return;
      }

      try {
        const userSalesRef = collection(db, 'users', currentUser.uid, 'sales');
        const querySnapshot = await getDocs(userSalesRef);

        if (querySnapshot.empty) {
          console.log("Inga försäljningsposter hittades i användarens sales-samling.");
          return;
        }

        const salesArray = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            ...data,
            saleId: doc.id,
          };
        });

        setUserSales(salesArray);
        setFilteredData(salesArray);
        calculateTotalSales(salesArray);
      } catch (error) {
        console.error("Fel vid hämtning av data:", error);
      }
    };

    fetchUserSales();
  }, [currentUser]);

  const handleFilterChange = () => {
    let filtered = [...userSales];

    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);

      filtered = filtered.filter((item) => {
        const itemDate = new Date(item.signDate);
        return itemDate >= start && itemDate <= end;
      });
    }

    setFilteredData(filtered);
    calculateTotalSales(filtered);
  };

  const calculateTotalSales = (data) => {
    const total = data.reduce((sum, item) => {
      const salesValue = item.amount || 0;
      return sum + parseFloat(salesValue);
    }, 0);
    setTotalSales(total);
  };

  const handleResetFilters = () => {
    setStartDate('');
    setEndDate('');
    setFilteredData(userSales);
    calculateTotalSales(userSales);
  };

  const handleCheckboxChange = (saleId) => {
    setSelectedSales((prevSelected) => {
      const updatedSelected = new Set(prevSelected);
      if (updatedSelected.has(saleId)) {
        updatedSelected.delete(saleId);
      } else {
        updatedSelected.add(saleId);
      }
      return updatedSelected;
    });
  };

  const handleDeleteSelected = async () => {
    if (!currentUser) return;

    try {
      for (let saleId of selectedSales) {
        await deleteDoc(doc(db, `users/${currentUser.uid}/sales/${saleId}`));
      }

      const updatedSales = userSales.filter(sale => !selectedSales.has(sale.saleId));
      setUserSales(updatedSales);
      setFilteredData(updatedSales);
      calculateTotalSales(updatedSales);
      setSelectedSales(new Set());
      setTotalSales(updatedSales.reduce((sum, sale) => sum + parseFloat(sale.amount || 0), 0));
    } catch (error) {
      console.error("Fel vid borttagning av försäljningsposter:", error);
    }
  };

  return (
    <div className="user-statistics-container">
      <h1>Min Statistik</h1>

      <div className="filter-container">
        <div className="filter-group">
          <label htmlFor="start-date">Startdatum:</label>
          <input
            type="date"
            id="start-date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className="filter-group">
          <label htmlFor="end-date">Slutdatum:</label>
          <input
            type="date"
            id="end-date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        <div className="filter-buttons">
          <button className="filter-button" onClick={handleFilterChange}>
            Filtrera
          </button>
          <button className="reset-button" onClick={handleResetFilters}>
            Nollställ
          </button>
          <button
            className="delete-button"
            onClick={handleDeleteSelected}
            disabled={selectedSales.size === 0}
          >
            Ta bort valda
          </button>
        </div>
      </div>

      <div className="total-sales">
        <h3>Totalt Försäljning: {totalSales} SEK</h3>
      </div>

      <div className="table-container">
        <table className="user-statistics-table">
          <thead>
            <tr>
              <th>Ta bort</th>
              <th>Kund</th>
              <th>Antal Aktier</th>
              <th>Datum ▲</th>
              <th>Företag</th>
              <th>Försäljning</th>
              <th>Status</th>
              <th>Betalningsdatum</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length === 0 ? (
              <tr>
                <td colSpan="8">Ingen data tillgänglig</td>
              </tr>
            ) : (
              filteredData.map((item, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedSales.has(item.saleId)}
                      onChange={() => handleCheckboxChange(item.saleId)}
                    />
                  </td>
                  <td>{item.name || 'N/A'}</td>
                  <td>{item.shares || '0'}</td>
                  <td>{item.signDate || 'N/A'}</td>
                  <td>{item.company || 'N/A'}</td>
                  <td>{item.amount ? `${item.amount} SEK` : '0 SEK'}</td>
                  <td>{item.status || 'Pending'}</td>
                  <td>{item.paidDate || 'N/A'}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserStatistics;