// ProtectedRoute.js
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';

const ProtectedRoute = ({ children, role }) => {
  const { currentUser, currentUserRole, isAddingUser } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [isProfileComplete, setIsProfileComplete] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const checkProfileCompletion = async () => {
      if (currentUser && ['user', 'sales-manager', 'admin', 'controller'].includes(currentUserRole)) {
        try {
          const userRef = doc(db, 'users', currentUser.uid);
          const userDoc = await getDoc(userRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            console.log('User data:', userData);

            // Kontrollera om alla obligatoriska fält är ifyllda
            const isComplete = !!(
              userData.name &&
              userData.personnummer &&
              userData.gatuadress &&
              userData.postnummerOrt &&
              userData.bank &&
              userData.clearingnummer &&
              userData.kontonummer &&
              userData.email &&
              userData.telefon &&
              userData.anhorig &&
              userData.anhorigTelefon
            );

            console.log('Is profile complete:', isComplete);
            setIsProfileComplete(isComplete);
          } else {
            console.log('Användardokument saknas. Kunde inte hitta användarens data.');
          }
        } catch (error) {
          console.error('Fel vid hämtning av användardata:', error);
        }
      } else {
        console.log('Användarroll kräver inte komplett profil eller användare är ej inloggad');
        setIsProfileComplete(true); // Om rollen inte kräver en komplett profil
      }
      setIsLoading(false); // Avsluta laddningen när datan är hämtad
    };

    if (!isAddingUser) {
      checkProfileCompletion();
    } else {
      console.log('Lägger till en ny användare, hoppar över profilkontroll');
      setIsLoading(false); // Om vi lägger till en användare, sätt isLoading till false
    }
  }, [currentUser, currentUserRole, location.pathname, isAddingUser]);

  // Omdirigera till "/login" om användaren inte är inloggad
  if (!currentUser) {
    console.log('Ingen användare inloggad. Omdirigerar till inloggningssidan.');
    return <Navigate to="/login" replace />;
  }

  // Visa laddningsskärm om data fortfarande hämtas
  if (isLoading) {
    console.log('Laddar användardata...');
    return <div>Laddar...</div>;
  }

  // Om profilen är ofullständig, omdirigera till "/profile" oavsett vilken sida användaren försöker nå
  if (!isProfileComplete && location.pathname !== '/profile') {
    console.log('Användarens profil är inte komplett. Omdirigerar till profilsidan.');
    return <Navigate to="/profile" replace />;
  }

  // Kontrollera användarrollen om en specifik roll krävs
  if (role) {
    const allowedRoles = Array.isArray(role) ? role : [role];
    if (!allowedRoles.includes(currentUserRole)) {
      console.log('Obehörig åtkomst - användarens roll matchar inte de tillåtna rollerna.');
      return <Navigate to="/unauthorized" replace />;
    }
  }

  // Rendera innehållet om allt är okej
  console.log('Användaren har behörighet. Renderar innehåll.');
  return children;
};

export default ProtectedRoute;