import React, { useState, useEffect, useCallback } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { useAuth } from '../auth';
import './styles/Dashboard.css';

const UserDashboard = () => {
  const [totalSales, setTotalSales] = useState(0);
  const [totalShares, setTotalShares] = useState(0);
  const [companyStats, setCompanyStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();

  const getCurrentMonthPeriod = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const start = `${year}-${month}-01`;
    const end = `${year}-${month}-${new Date(year, today.getMonth() + 1, 0).getDate()}`;
    return { start, end };
  };

  const fetchUserSalesData = useCallback(async () => {
    const { start, end } = getCurrentMonthPeriod();
    try {
      const salesCollectionRef = collection(db, `users/${currentUser.uid}/sales`);
      const salesSnapshot = await getDocs(salesCollectionRef);
      let totalSalesAmount = 0;
      let totalShares = 0;
      const companies = {};

      salesSnapshot.forEach(saleDoc => {
        const saleData = saleDoc.data();
        const saleSignDate = saleData.signDate;

        if (saleSignDate >= start && saleSignDate <= end) {
          totalSalesAmount += parseFloat(saleData.amount || 0);
          totalShares += parseFloat(saleData.shares || 0);

          const company = saleData.company || 'Okänd';
          if (!companies[company]) {
            companies[company] = { salesCount: 0, sales: 0, shares: 0 };
          }
          companies[company].salesCount += 1;
          companies[company].sales += parseFloat(saleData.amount || 0);
          companies[company].shares += parseFloat(saleData.shares || 0);
        }
      });

      setTotalSales(totalSalesAmount);
      setTotalShares(totalShares);

      const companyStatsArray = Object.entries(companies).map(([company, stats]) => ({
        company,
        ...stats
      }));
      setCompanyStats(companyStatsArray);
    } catch (error) {
      console.error("Error fetching user sales data:", error);
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      setLoading(true);
      fetchUserSalesData().then(() => setLoading(false));
    }
  }, [currentUser, fetchUserSalesData]);

  return (
    <div className="dashboard-content">
      <h1 className="dashboard-title">Min Försäljningsdashboard</h1>
      <button className="refresh-btn" onClick={fetchUserSalesData}>Uppdatera Data</button>

      {loading ? (
        <p>Laddar...</p>
      ) : (
        <>
          <div className="stats-grid">
            <div className="stats-card">
              <h2>Totala summan av försäljningar</h2>
              <p className="stat-number">{totalSales.toFixed(2)} SEK</p>
            </div>
            
            <div className="stats-card">
              <h2>Totalt antal aktier</h2>
              <p className="stat-number">{totalShares}</p>
            </div>
          </div>

          <h2 className="dashboard-title" style={{ marginTop: '30px' }}>Försäljning per Företag</h2>
          <div className="company-stats-box">
            {companyStats.map((company, index) => (
              <div className="company-stats-box-card" key={index}>
                <h3>{company.company}</h3>
                <p className="stat"><strong>Antal försäljningar:</strong> {company.salesCount}</p>
                <p className="stat"><strong>Totalt antal aktier:</strong> {company.shares}</p>
                <p className="stat"><strong>Totala summan av försäljningar:</strong> {company.sales.toFixed(2)} SEK</p>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default UserDashboard;