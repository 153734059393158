import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc, getDoc, query, where } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import './styles/SalesSpecification.css';

const SalesSpecification = () => {
  const [selectedPeriod, setSelectedPeriod] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [selectedPaymentDate, setSelectedPaymentDate] = useState('');
  const [employees, setEmployees] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const [formData, setFormData] = useState({
    totalSales: 0,
    commission: '',
    kickSales: 0, // Nytt fält för kick försäljning
    salary: 0,
  });
  const [periods, setPeriods] = useState([
    '18 April - 17 Maj',
    '18 Maj - 17 Juni',
    '18 Juni - 17 Juli',
  ]);
  const [customPeriod, setCustomPeriod] = useState('');
  const [paymentDates, setPaymentDates] = useState([]);

  useEffect(() => {
    const fetchEmployees = async () => {
      const querySnapshot = await getDocs(collection(db, 'users'));
      const employeeList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setEmployees(employeeList);
    };

    fetchEmployees();
  }, []);

  useEffect(() => {
    const fetchPaymentDates = async () => {
      if (selectedEmployee) {
        const salesRef = collection(db, `users/${selectedEmployee}/sales`);
        const q = query(salesRef, where('paid', '==', true));
        const querySnapshot = await getDocs(q);
        const datesSet = new Set();

        querySnapshot.forEach(doc => {
          const data = doc.data();
          if (data.paidDate) {
            datesSet.add(data.paidDate);
          }
        });

        setPaymentDates([...datesSet]);
      } else {
        setPaymentDates([]);
      }
    };

    fetchPaymentDates();
  }, [selectedEmployee]);

  useEffect(() => {
    const fetchSalesData = async () => {
      if (selectedEmployee && selectedPaymentDate) {
        const salesRef = collection(db, `users/${selectedEmployee}/sales`);
        const q = query(
          salesRef,
          where('paid', '==', true),
          where('paidDate', '==', selectedPaymentDate)
        );
        const querySnapshot = await getDocs(q);
        const salesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setSalesData(salesList);

        // Beräkna total försäljning
        const totalSalesAmount = salesList.reduce((sum, sale) => sum + (parseFloat(sale.amount) || 0), 0);

        setFormData(prevState => {
          const commission = parseFloat(prevState.commission) || 0;
          const kickSales = parseFloat(prevState.kickSales) || 0; // Inkludera kick försäljning
          const salary = (totalSalesAmount + kickSales) * (commission / 100);
          return {
            ...prevState,
            totalSales: totalSalesAmount,
            salary: salary >= 0 ? salary : 0,
          };
        });
      } else {
        setSalesData([]);
        setFormData(prevState => ({
          ...prevState,
          totalSales: 0,
          salary: 0,
        }));
      }
    };

    fetchSalesData();
  }, [selectedEmployee, selectedPaymentDate]);

  const handleAddPeriod = () => {
    if (customPeriod && !periods.includes(customPeriod)) {
      setPeriods([...periods, customPeriod]);
      setCustomPeriod('');
    } else {
      alert('Vänligen ange en unik löneperiod.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => {
      const updatedFormData = { ...prevState, [name]: value };

      // Beräkna lön när provisionen eller kick försäljningen ändras
      if (name === 'commission' || name === 'kickSales') {
        const totalSales = parseFloat(updatedFormData.totalSales) || 0;
        const commission = parseFloat(updatedFormData.commission) || 0;
        const kickSales = parseFloat(updatedFormData.kickSales) || 0;

        const salary = (totalSales + kickSales) * (commission / 100);
        updatedFormData.salary = salary >= 0 ? salary : 0;
      }

      return updatedFormData;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedEmployee && selectedPeriod) {
      try {
        const userRef = doc(db, 'users', selectedEmployee);
        const userDoc = await getDoc(userRef);

        // Hämta befintliga specifikationer om de finns
        const existingSpecifications = userDoc.data().salesSpecifications || {};

        // Lägg till eller uppdatera specifikationen för den valda perioden
        existingSpecifications[selectedPeriod] = {
          ...formData,
          customerList: salesData, // Lägg till kundlistan
        };

        // Uppdatera användarens dokument med den nya specifikationen
        await updateDoc(userRef, {
          salesSpecifications: existingSpecifications
        });

        alert('Säljspecifikation sparad!');
      } catch (error) {
        console.error('Fel vid uppdatering av säljspecifikation:', error);
        alert('Kunde inte spara säljspecifikationen.');
      }
    } else {
      alert('Vänligen välj en anställd och en löneperiod.');
    }
  };

  return (
    <div className="sales-specification-container">
      <h1>Säljspecifikation</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="employee">Anställd</label>
        <select
          id="employee"
          value={selectedEmployee}
          onChange={(e) => {
            setSelectedEmployee(e.target.value);
            setSelectedPaymentDate('');
          }}
        >
          <option value="">Välj en anställd</option>
          {employees.map((employee) => (
            <option key={employee.id} value={employee.id}>
              {employee.firstName} {employee.lastName}
            </option>
          ))}
        </select>

        <label htmlFor="paymentDate">Utbetalningsdag</label>
        <select
          id="paymentDate"
          value={selectedPaymentDate}
          onChange={(e) => setSelectedPaymentDate(e.target.value)}
          disabled={!selectedEmployee}
        >
          <option value="">Välj utbetalningsdag</option>
          {paymentDates.map((date, index) => (
            <option key={index} value={date}>
              {date}
            </option>
          ))}
        </select>

        <label htmlFor="period">Löneperiod</label>
        <select
          id="period"
          value={selectedPeriod}
          onChange={(e) => setSelectedPeriod(e.target.value)}
        >
          <option value="">Välj en period</option>
          {periods.map((period, index) => (
            <option key={index} value={period}>
              {period}
            </option>
          ))}
        </select>

        <input
          type="text"
          value={customPeriod}
          onChange={(e) => setCustomPeriod(e.target.value)}
          placeholder="Ange egen löneperiod"
        />
        <button type="button" onClick={handleAddPeriod}>
          Lägg till period
        </button>

        <h2>Kundlista</h2>
        {salesData.length > 0 ? (
          <table className="sales-table">
            <thead>
              <tr>
                <th>Kundnamn</th>
                <th>Antal Aktier</th>
                <th>Summa</th>
              </tr>
            </thead>
            <tbody>
              {salesData.map((sale) => (
                <tr key={sale.id}>
                  <td>{sale.name || 'Namn saknas'}</td>
                  <td>{sale.shares || 'N/A'}</td>
                  <td>{sale.amount ? parseFloat(sale.amount).toFixed(2) : 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>Inga försäljningar hittades för vald anställd och utbetalningsdag.</p>
        )}

        <h2>Löneberäkning</h2>
        <label htmlFor="totalSales">Total försäljning:</label>
        <input
          type="number"
          id="totalSales"
          name="totalSales"
          value={formData.totalSales.toFixed(2)}
          readOnly
        />

        <label htmlFor="kickSales">Kick Försäljning:</label>
        <input
          type="number"
          id="kickSales"
          name="kickSales"
          value={formData.kickSales}
          onChange={handleInputChange}
        />

        <label htmlFor="commission">Provision (%):</label>
        <input
          type="number"
          id="commission"
          name="commission"
          value={formData.commission}
          onChange={handleInputChange}
        />

        <label htmlFor="salary">Lön:</label>
        <input
          type="number"
          id="salary"
          name="salary"
          value={formData.salary.toFixed(2)}
          readOnly
        />

        <button type="submit">Spara</button>
      </form>
    </div>
  );
};

export default SalesSpecification;