import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebaseConfig';
import { collection, doc, addDoc, getDocs, deleteDoc } from 'firebase/firestore';
import './styles/Sales.css';

const Sales = () => {
  const [salesData, setSalesData] = useState({
    name: '',
    company: '',
    shares: '',
    amount: '',
    signDate: '',
    status: 'Pending',
  });
  const [salesList, setSalesList] = useState([]);
  const [companyList, setCompanyList] = useState([]); // Företagslista för dropdown
  const [message, setMessage] = useState('');

  // Hämta tidigare försäljningsdata och företagslista från Firestore
  useEffect(() => {
    const fetchSalesData = async () => {
      const userId = auth.currentUser?.uid;
      if (!userId) {
        setMessage("Ingen användare är inloggad.");
        return;
      }

      try {
        const salesCollectionRef = collection(db, `users/${userId}/sales`);
        const querySnapshot = await getDocs(salesCollectionRef);
        const salesArray = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        // Sortera så att den senaste försäljningen visas först
        setSalesList(salesArray.sort((a, b) => new Date(b.signDate) - new Date(a.signDate)));
      } catch (error) {
        console.error('Error fetching sales data:', error);
        setMessage('Ett fel uppstod vid hämtning av försäljningsdata.');
      }
    };

    const fetchCompanies = async () => {
      try {
        const companiesCollectionRef = collection(db, 'companies');
        const querySnapshot = await getDocs(companiesCollectionRef);
        const companiesArray = querySnapshot.docs.map(doc => ({
          id: doc.id,
          name: doc.data().name,
        }));
        setCompanyList(companiesArray);
      } catch (error) {
        console.error('Error fetching companies:', error);
        setMessage('Ett fel uppstod vid hämtning av företag.');
      }
    };

    fetchSalesData();
    fetchCompanies();
  }, []);

  // Hantera ändringar i inmatningsfält
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSalesData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Hantera inlämning av försäljning
  const handleSubmit = async (e) => {
    e.preventDefault();
    const userId = auth.currentUser?.uid;
    if (!userId) {
      setMessage("Ingen användare är inloggad.");
      return;
    }

    try {
      const salesCollectionRef = collection(db, `users/${userId}/sales`);
      const docRef = await addDoc(salesCollectionRef, {
        name: salesData.name,
        company: salesData.company,
        shares: parseInt(salesData.shares),
        amount: parseFloat(salesData.amount),
        signDate: salesData.signDate,
        status: salesData.status,
      });

      setMessage('Försäljningsdata tillagd!');
      // Lägg till den nya posten i början av listan
      setSalesList([{ id: docRef.id, ...salesData }, ...salesList]);

      setSalesData({
        name: '',
        company: '',
        shares: '',
        amount: '',
        signDate: '',
        status: 'Pending',
      });
    } catch (error) {
      console.error('Error adding sales data:', error);
      setMessage('Ett fel uppstod vid tillägg av försäljningsdata.');
    }
  };

  // Hantera borttagning av försäljningspost
  const handleDelete = async (saleId) => {
    const userId = auth.currentUser?.uid;
    if (!userId) {
      setMessage("Ingen användare är inloggad.");
      return;
    }

    try {
      const saleDocRef = doc(db, `users/${userId}/sales/${saleId}`);
      await deleteDoc(saleDocRef);

      setSalesList(salesList.filter(sale => sale.id !== saleId));
      setMessage('Försäljningspost borttagen!');
    } catch (error) {
      console.error('Error deleting sales data:', error);
      setMessage('Ett fel uppstod vid borttagning av försäljningsdata.');
    }
  };

  return (
    <div className="sales-container">
      <h1>Rapportera Försäljning</h1>
      <form onSubmit={handleSubmit} className="sales-form">
        <div className="form-group">
          <label>Namn:</label>
          <input
            type="text"
            name="name"
            value={salesData.name}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Företag:</label>
          <select
            name="company"
            value={salesData.company}
            onChange={handleInputChange}
            required
          >
            <option value="">Välj ett företag</option>
            {companyList.map((company) => (
              <option key={company.id} value={company.name}>
                {company.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Antal Aktier:</label>
          <input
            type="number"
            name="shares"
            value={salesData.shares}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Summa:</label>
          <input
            type="number"
            name="amount"
            value={salesData.amount}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Datum Signering:</label>
          <input
            type="date"
            name="signDate"
            value={salesData.signDate}
            onChange={handleInputChange}
            required
          />
        </div>
        <button type="submit">Lägg till försäljning</button>
      </form>

      {message && <p className="message">{message}</p>}

      <h2>Försäljningslista</h2>
      <ul className="sales-list">
        {salesList.map((sale) => (
          <li key={sale.id} className="sales-item">
            <p><strong>Namn:</strong> {sale.name}</p>
            <p><strong>Företag:</strong> {sale.company}</p>
            <p><strong>Antal Aktier:</strong> {sale.shares}</p>
            <p><strong>Summa:</strong> {sale.amount} kr</p>
            <p><strong>Datum Signering:</strong> {sale.signDate}</p>
            <p><strong>Status:</strong> {sale.status}</p>
            <button onClick={() => handleDelete(sale.id)} className="delete-button">Ta bort</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sales;