import React, { useState, useEffect, useCallback } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import './styles/Dashboard.css';

const Dashboard = () => {
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [totalShares, setTotalShares] = useState(0);
  const [totalSalesAmount, setTotalSalesAmount] = useState(0);
  const [companyStats, setCompanyStats] = useState([]);
  const [paidCount, setPaidCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const getCurrentMonthPeriod = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const start = `${year}-${month}-01`;
    const end = `${year}-${month}-${new Date(year, today.getMonth() + 1, 0).getDate()}`;
    return { start, end };
  };

  const fetchSalesData = useCallback(async () => {
    const { start, end } = getCurrentMonthPeriod();
    
    try {
      const usersSnapshot = await getDocs(collection(db, 'users'));

      // Lokalt objekt för att samla statistik innan state-uppdatering
      const stats = {
        customerCount: 0,
        totalShares: 0,
        totalSalesAmount: 0,
        paidCount: 0,
        pendingCount: 0,
        companies: {},
      };

      for (const userDoc of usersSnapshot.docs) {
        const userId = userDoc.id;
        const salesCollectionRef = collection(db, `users/${userId}/sales`);
        const salesSnapshot = await getDocs(salesCollectionRef);

        salesSnapshot.forEach(saleDoc => {
          const saleData = saleDoc.data();
          const saleSignDate = saleData.signDate;
          const saleStatus = saleData.status || 'Pending';

          if (saleSignDate >= start && saleSignDate <= end) {
            stats.customerCount += 1;
            stats.totalShares += parseFloat(saleData.shares || 0);
            stats.totalSalesAmount += parseFloat(saleData.amount || 0);

            if (saleStatus.toLowerCase() === 'betald') {
              stats.paidCount += 1;
            } else if (saleStatus.toLowerCase() === 'pending') {
              stats.pendingCount += 1;
            }

            const company = saleData.company || 'Okänd';
            if (!stats.companies[company]) {
              stats.companies[company] = { customers: 0, shares: 0, salesAmount: 0 };
            }
            stats.companies[company].customers += 1;
            stats.companies[company].shares += parseFloat(saleData.shares || 0);
            stats.companies[company].salesAmount += parseFloat(saleData.amount || 0);
          }
        });
      }

      // Uppdatera state efter loopen
      setTotalCustomers(stats.customerCount);
      setTotalShares(stats.totalShares);
      setTotalSalesAmount(stats.totalSalesAmount);
      setPaidCount(stats.paidCount);
      setPendingCount(stats.pendingCount);

      const companyStatsArray = Object.entries(stats.companies).map(([company, companyStats]) => ({
        company,
        ...companyStats,
      }));
      setCompanyStats(companyStatsArray);
      
    } catch (error) {
      console.error("Error fetching sales data:", error);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchSalesData().then(() => setLoading(false));
  }, [fetchSalesData]);

  return (
    <div className="dashboard-content">
      <h1 className="dashboard-title">Dashboard</h1>
      <button className="refresh-btn" onClick={fetchSalesData}>Uppdatera Data</button>

      {loading ? (
        <p>Laddar...</p>
      ) : (
        <>
          <div className="stats-grid">
            <div className="stats-card">
              <h2>Totalt antal kunder</h2>
              <p className="stat-number">{totalCustomers}</p>
            </div>
            <div className="stats-card">
              <h2>Totalt antal aktier</h2>
              <p className="stat-number">{totalShares}</p>
            </div>
            <div className="stats-card">
              <h2>Totala summan av försäljningar</h2>
              <p className="stat-number">{totalSalesAmount.toFixed(2)} SEK</p>
            </div>
            <div className="stats-card">
              <h2>Antal betalda</h2>
              <p className="stat-number">{paidCount}</p>
            </div>
            <div className="stats-card">
              <h2>Antal pending</h2>
              <p className="stat-number">{pendingCount}</p>
            </div>
          </div>

          <h2 className="dashboard-title" style={{ marginTop: '30px' }}>Statistik per Företag</h2>
          <div className="company-stats-box">
            {companyStats.map((company, index) => (
              <div className="company-stats-box-card" key={index}>
                <h3>{company.company}</h3>
                <p className="stat"><strong>Antal kunder:</strong> {company.customers}</p>
                <p className="stat"><strong>Totalt antal aktier:</strong> {company.shares}</p>
                <p className="stat"><strong>Totala summan av försäljningar:</strong> {company.salesAmount.toFixed(2)} SEK</p>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;