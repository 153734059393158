// MainLayout.js
import React, { useState } from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { useAuth } from '../auth';
import { auth } from '../firebaseConfig';
import './styles/Layout.css';

const MainLayout = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { currentUserRole } = useAuth();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error logging out: ', error);
    }
  };

  return (
    <div className="layout">
      <header className="header">
        <div className="menu-icon" onClick={toggleMenu}>
          &#9776;
        </div>
        <h1>Talk4you</h1>
      </header>
      
      <div className={`sidebar ${isMenuOpen ? 'open' : ''}`}>
        <nav>
          <ul>
            {/* Admin Meny */}
            {currentUserRole === 'admin' && (
              <>
                <li><Link to="/admin/dashboard">Dashboard</Link></li>
                <li><Link to="/add-user">Lägg till användare</Link></li>
                <li><Link to="/admin/manage-users">Hantera användare</Link></li>
                <li><Link to="/sales-info">Försäljningsinfo</Link></li>
                <li><Link to="/sales-specification">Försäljningsspecifikation</Link></li>
                <li><Link to="/admin/salary-statistics">Lönestatistik</Link></li>
                <li><Link to="/admin/statistics">Statistik</Link></li> {/* Ny länk till Admin Statistik */}
              </>
            )}

            {/* Sales Manager Meny */}
            {currentUserRole === 'sales-manager' && (
              <>
                <li><Link to="/sales-manager/dashboard">Dashboard</Link></li>
                <li><Link to="/add-user">Lägg till teammedlem</Link></li> 
                <li><Link to="/user/sales-specification">Säljspecifikation</Link></li>
                <li><Link to="/sales-manager/manage-users">Hantera teammedlemmar</Link></li>
                <li><Link to="/sales-manager/final-report">Slutrapport</Link></li>
                <li><Link to="/sales-manager/statistics">Statistik</Link></li> {/* Ny länk till Sales Manager Statistik */}
                <li><Link to="/sales">Rapportera Försäljning</Link></li> {/* Ny länk till Sales-komponenten */}
              </>
            )}

            {/* Controller Meny */}
            {currentUserRole === 'controller' && (
              <>
                <li><Link to="/controller/dashboard">Dashboard</Link></li>
                <li><Link to="/controller/statistics">Controller Statistik</Link></li>
                <li><Link to="/controller/Företag">Hantera Företag</Link></li> {/* Ny länk till Companies */}
              </>
            )}

            {/* User Meny */}
            {currentUserRole === 'user' && (
              <>
                <li><Link to="/user/dashboard">Start</Link></li>
                <li><Link to="/user/sales-specification">Säljspecifikation</Link></li>
                <li><Link to="/user/statistics">Min Statistik</Link></li>
                <li><Link to="/sales">Rapportera Försäljning</Link></li>
              </>
            )}

            {/* Profil och Logout */}
            <li><Link to="/profile">Min Profil</Link></li>
            <li><button onClick={handleLogout} className="logout-button">Logga ut</button></li>
          </ul>
        </nav>
      </div>
      
      <main className="content">
        <Outlet />
      </main>
    </div>
  );
};

export default MainLayout;