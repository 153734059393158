import React, { useEffect, useState } from 'react';
import { useAuth } from '../auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import './styles/UserSalesSpecification.css';

const UserSalesSpecification = () => {
  const { currentUser } = useAuth();
  const [userData, setUserData] = useState(null);  // To store user data
  const [salesSpecification, setSalesSpecification] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);
        const data = userDoc.data();
        if (data) {
          setUserData(data);  // Save user's name and sales ID
          if (data.salesSpecifications) {
            setSalesSpecification(data.salesSpecifications);  // Save sales specifications
          }
        }
      }
    };

    fetchUserData();
  }, [currentUser]);

  const handlePeriodChange = (e) => {
    setSelectedPeriod(e.target.value);
  };

  return (
    <div className="user-sales-specification-container">
      <h1>Säljspecifikation</h1>
      
      {userData ? (
        <>
          <p><strong>Säljare:</strong> {userData.firstName ? `${userData.firstName} ${userData.lastName}` : 'N/A'}</p>
          <p><strong>Säljid:</strong> {userData.salesId || 'N/A'}</p>
          <br />

          <label htmlFor="period">Välj Löneperiod</label>
          <select id="period" value={selectedPeriod} onChange={handlePeriodChange}>
            <option value="">Välj en period</option>
            {salesSpecification && Object.keys(salesSpecification).map((period, index) => (
              <option key={index} value={period}>
                {period}
              </option>
            ))}
          </select>

          {selectedPeriod && salesSpecification && (
            <div className="specification-details">
              <h2>LÖNESPECIFIKATION</h2>
              <p><strong>Försäljningsperiod:</strong> {selectedPeriod}</p>

              <h3>Kundlista</h3>
              {salesSpecification[selectedPeriod].customerList && salesSpecification[selectedPeriod].customerList.length > 0 ? (
                <table className="customer-table">
                  <thead>
                    <tr>
                      <th>Kundnamn</th>
                      <th>Antal Aktier</th>
                      <th>Summa</th>
                    </tr>
                  </thead>
                  <tbody>
                    {salesSpecification[selectedPeriod].customerList.map((customer, index) => (
                      <tr key={index}>
                        <td>{customer.name || 'Namn saknas'}</td>
                        <td>{customer.shares || 'N/A'}</td>
                        <td>{customer.amount ? parseFloat(customer.amount).toFixed(2) : 'N/A'}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>Ingen kundlista tillgänglig för denna period.</p>
              )}

              <h3>Löneberäkning</h3>
              <p><strong>Total försäljning:</strong> {salesSpecification[selectedPeriod].totalSales.toFixed(2)} kr</p>
              <p><strong>Provision (%):</strong> {salesSpecification[selectedPeriod].commission}%</p>
              <p><strong>Lön:</strong> {salesSpecification[selectedPeriod].salary.toFixed(2)} kr</p>
            </div>
          )}
        </>
      ) : (
        <p>Hämtar användarinformation...</p>
      )}
    </div>
  );
};

export default UserSalesSpecification;